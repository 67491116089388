.container-cart {
    background-color: $white;
    @media screen and (min-width: $small-width) {
         padding: 60px;
    }
    font-family: $font-family--secondary;
    h1 {
        font-size: 45px;
        font-family: $font-family--primary;
        color: #313236;
        text-align: center;
    }
    .action-buttons {
        text-align: center;
        margin-top:30px;
        @media screen and (min-width: $small-width) {
            text-align: initial; 
        }
        .btn-order-now, .btn-continue-shopping, .btn-empty-cart{
            width: 100%;
            margin: 10px 0; 
            @media screen and (min-width: $medium-width) {
                width: 280px;
            }
        }
    } 
    .cart-header{
         text-align: center;
        @media screen and (min-width: $small-width) {
            text-align: left;
        }
        .notice {
        display:block;
        width:100%;
        padding-top:2px;
        font-size:16px;
        clear:both;
        color:$black;
        text-align: center;
        }

    }
    .cart-listing {
        display:block;
        width:100%;
        clear:both;
        padding: 15px 0px;
        .item-category {
            display:block;
            width:100%;
            margin-bottom: 15px;
            .item-category-header {
                clear:both;
                background-color:#d6d6d6;
                line-height:40px;
                vertical-align: middle;
                text-align: center;
                height: 120px;
                margin-bottom: 10px;
                padding: 10px; 
                @media screen and (min-width: $small-width) {
                    text-align: left;
                    height: 50px;
                    padding: 0;
                }
               .btn-shop-more {
                    float: none;
                    line-height:30px;
                    vertical-align:middle;
                    width: 100%;
                    font-size:14px;
                    margin-top:0;
                    padding: 10px;
                   
                    @media screen and (min-width: $small-width) {
                        float:right;
                    }
                }
                .vehicle{
                    font-size: 20px;
                    color: $crimson;
                }
            }
        }
        .container-btn-shop-more, .vehicle{
            @media screen and (min-width: $small-width) {
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .item-list {
            width: 100%;
            font-size: 14px;
            @media screen and (min-width: $small-width) {
                font-size: 16px;
            }
            hr{
                background-color: $gray-light;
            }  
            .item{
                color: $shark;
                padding-top: 10px;
                
                .item-part-type-number, .item-manufacturer, .item-unit-price, .item-quantity, .item-total, .item-delete, .item-price, .item-description, .item-part-type{
                    padding: 10px;
                    @media screen and (min-width: $small-width) {
                        font-size: 16px;
                        text-align: center;
                    }
                }
                .item-quantity {
                    .fa-spin {
                        display: inline-table;
                        color: $crimson;
                    } 
                    input{
                        border: 1px solid #DCDCDC;
                        text-align: right;
                        width: 50px;
                        }
                }
                .item-price, .item-unit-price,  .item-total{
                    text-align: center; 
                }
              
                .item-part-type-number{
                     font-size: 14px;
                     font-weight: bold;
                      @media screen and (min-width: $small-width) {
                        font-weight: normal;
                       
                     }
                }
                .item-delete{
                    text-align: center;
                }
                &:not(:last-of-type){
                    border-bottom: 1px solid $gray-light;
                }
                
                td {
                    padding: 10px;
                }
                &.wait-to-merge td {
                    background-color:#ffb900;
                }
                .actions i {
                    font-size:24px;
                    cursor:pointer;
                    color:#EE0000;
                }
            }
        }
        .no-items {
            display:block;
            width: 100%;
            text-align:center;
            line-height:100px;
            vertical-align:middle;
            font-style: italic;
            font-size: 18px;
            border:1px solid #a6a6a6;
        }
        .item-labels{
            text-align: left;
            font-size: 12px;
            border-bottom: 1px solid $gray-light;
            @media screen and (min-width: $medium-width) {
                font-size: 16px;
            }
            .item-label{
                padding: 10px;
                font-weight: bold;
                text-align: center;
                
            }
        }
    }
    
    .grand-total {
        height:50px;
        border-top: 1px solid $gray-light;
        font-size: 14px;
            @media screen and (min-width: $small-width) {
                font-size: 16px;
            }
        .amount, .total-label{
            top: 50%;
            transform: translateY(-50%);
            font-weight:bold;
            text-align: center;
            padding: 10px;
        }
        .total-label{
            text-align: right;
        }
    
    }
    .no-items {
        margin: 0;
    }
    .row{
        text-align: center;
        @media screen and (min-width: $small-width) {
            text-align: left;
        }
    }

    .sub-amount{
        text-align: left;
        @media screen and (min-width: $small-width) {
            text-align: right;
        }
    }
 }
.container-confirmation{
    background-color: $white;
     .item-labels{
        text-align: center;
        font-weight: bold;
        padding: 5px 0; 
        border-bottom: 1px solid $gray-light;

        .item-label{
             padding: 10px;
              font-size: 10px;
             @media screen and (min-width: $medium-width) {
                font-size: 16px;
             }
            
            
        }
    }
     .item-description{
        word-break: break-all; 
    }
    .item-list{
        
        .item-row{
             border-bottom: 1px solid $gray-light;
             padding-bottom: 10px;
            div{
                padding: 0 10px;
            }
        } 
    }
    .price-row div{
            padding: 0 10px;
        }
  
}

.delivery-method-radio {
    margin-top: 5px;
}