.site-footer {
	border-top: $crimson 3px solid;
	font-family: $font-family--secondary;
	font-size: 14px;
	text-align:center;
	position: relative;
	@media screen and (min-width: $medium-width) {
		text-align:left;
	}
	&__background-container{
		background-color: $color-secondary;
		background-image: url('../images/k-kobrex-gray.svg');
    	background-repeat: no-repeat;
    	background-position:  20px -10px;  
    	background-size: 800px;
    	padding-top:$spacing-xxlarge; 
	}
	.contact-us {
		border-right: none;

		&-label{
			color: $gray-base;
			display:inline-block;
		}
	}
	&__copyright {
		padding-top: $spacing-base-vertical;
		padding-bottom: $spacing-base-vertical;
		margin-top: $spacing-medium;
		background-color: $color-primary;
		font-size: em(12px);
		color: $white;
		text-align: center;
        .container-copyright{
            text-align: center;
            @media screen and (min-width: $medium-width) {
                text-align: left;
            }
        }
        .container-policy{
            text-align: center;
             @media screen and (min-width: $medium-width) {
                text-align: right;
            }
            a{
                @media screen and (min-width: $medium-width) {
                    padding-left: 20px;
                }
            }
        }
	}
	&__disclaimer{
		font-size: 12px;
		color: $gray-light;
	}
	.fa{
		color:$white;
		margin-bottom:$spacing-xxlarge; 
		font-size: 35px;
		margin-right:5px;

		&:hover{
			color: $gray-base;
			cursor:pointer;
			@extend %transition-base;
		}
	}
   h3 {
        font-weight: $font-weight--bold;
        font-family: $font-family--primary;
        font-size: 20px;
        color: $white;
    }
    &__icon-chat{
    	width:100%;
    	text-align:center;
    	margin-bottom: $spacing-large;
    }

    &__logo {
		width:270px; 
		height: 37px;
		margin-bottom:35px; 

	}
	&__logo-paypal img{
		width: 120px;
		margin-top: $spacing-medium;
		@media screen and (min-width: $medium-width) {
			float:right;
		}
	}
	&__menu li{
		list-style : none;
		padding-left:0;
		margin-bottom: 15px;
		background-size:cover;
		width: inherit;

		@media screen and (min-width: $medium-width) {
			background: url('../images/icon-arrow.svg');
			background-size: 25px;
    		background-repeat: no-repeat;
			padding-left:30px;
		}
		a{
			color:$white;
			text-transform: uppercase;
		}
	}
    .mc_embed_signup{
       
        form{
            padding: 0; 
        }
        input[type=submit]{
            border: 0;
            text-transform: uppercase;
            margin-top: 2px;

        }
        input.email{
            display: inline-block;
            padding: 10px;
            background-color: $white;
            border-radius: 0;
            margin: 0; 
            width: 50%; 
            min-width: 100px; 
        }
    }
	p {
		color: $white;
		margin-bottom:20px;
    }
    .subscription {
        button {
            width: 100%;
        }
        p {
            margin-bottom: 0;
        }
        .title {
            font-weight: 700;
            text-transform: uppercase;
            font-family: Khand,"Helvetica Neue",Helvetica,Arial,sans-serif;
            font-size: 20px;
            color: #FFF;
        }
    }
	&__section {
		padding-left: $spacing-medium-horizontal;
		padding-right: $spacing-medium-horizontal;
		margin-bottom: $spacing-medium-vertical;
		margin-bottom: $spacing-large-vertical;

		@media screen and (min-width: $small-width) {
			margin-bottom: $spacing-xxlarge-vertical;
			border-right: 1px solid $br--color;
		}
	
	}
    // Work around for same height section
	@media screen and (min-width: $medium-width) {
		
		&__section {
			display: table-cell;
			float: none;
		}

		._table {
			display: table;	
			width: 100%;
			margin-bottom:60px;
		}
	}

}