.btn {
	cursor: pointer;
	position: relative;
	display: inline-block;
	padding: 12px 10px;
    margin-top: 20px;
	text-decoration: none;
	font-family: $font-family--secondary; 
	font-size: em($base__font-size);
	font-weight: $font-weight--bold;
	color: $white;
    text-align: center;
	vertical-align: middle;
	background: linear-gradient(0deg, rgba(235,32,49,1) 0%, rgba(235,32,49,1) 63%, rgba(235,32,49,1) 100%); /* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-primary', endColorstr='$color-primary',GradientType=0 ); /* ie6-9 */
    
    transform-style: preserve-3d;
	overflow: hidden;

	@media only screen and (min-width: $xsmall-width) {
		font-size: em(14px);
	}
    @media only screen and (max-width: $small-width) {
       //width: 100%;
    }
	
    @media only screen and (min-width: $nohover-width) {
        &:not(.disabled):hover{
            text-decoration: none;

            &:after {
                text-decoration: none;
                opacity: 0;
                transform: scale3d(1,1,1) skew(25deg,0) translate3d(-100%,0,0);
            }


            &:after {
                opacity: 0.35;
                transform: scale3d(1.2,1.2,1.2) skew(25deg,0) translate3d(0,0,0);
            }
        }
    } 
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: $white;
        transform: scale3d(1,1,1) skew(25deg,0) translate3d(-100%,0,0);
        @extend %transition-base;
    }
	
	&:focus {
		outline: none;
	}
    &-background-shark{
            background: linear-gradient(0deg, rgba(49,50,54,1) 0%, rgba(49,50,54,1) 63%, rgba(49,50,54,1) 100%); /* w3c */
        }
	&--browse{
		float:left;
		margin-right: 10px;
	}
	
	&--upper {
		text-transform: uppercase;
	}
    
    &--text-centered {
        text-align: center;
    }
    
	&--icon{
		padding-left: 50px;

		&:before {
			position: absolute;
			color: $white;
			top:50%; 
			transform: translateY(-50%);
			font-family: $font-family--icon;
			font-weight: $font-weight--thin;
			z-index: 1;
		}

		&:after {
			padding-left: 20px;
		}

	} // close icon

    &--inverse{
        background: transparent;
        border: 1px solid $crimson;
        @extend %transition-base;
        color: $shark;
        margin-bottom: 20px;
        &:hover{
            background: $gray-base;
            color: $black;
        }
    }

	&--reset {
		position: relative;
		padding-top: 13px;
		padding-bottom: 13px;
		padding-left: 55px;
		width: 100%;

		&:before {
			content: "\f01e";
			position: absolute;
			left: 12px; top: 5px;
			font-size: 30px;
			font-family: $font-family--icon;
		}

	}

	&--search {
		width: 100%;
		text-transform: uppercase;
		text-align: center;

		padding: 5px;
		vertical-align: middle;

		&:before {
			content: "\f021";
			left: 0;
			font-size: 30px;
			font-family: $font-family--icon;
			padding-right: 20px;
		}

		&[data-js-sticky] {
			min-width: 150px;
			box-shadow:         2px 2px 17px 0px rgba(50, 50, 50, 0.5);
		}
	}

	

	&--view {
		width: 100%;
		text-align: center;

		@media screen and (min-width: $medium-width){
			position: absolute;
			z-index: 150;
			width: 85%;
			top: 0;
		}
		
		&:before {
			content: "\f03a";
			left: 15px;
			font-size: 20px;
			font-family: $font-family--icon;
			margin-right: 15px;
		}
	}

	&--download {
		width: 100%;
		margin: 20px 0;
		&:before {
			content: "\f019";
			left: 15px;
			font-size: 20px;
			font-family: $font-family--icon;
			margin-right: 15px;
		}
	}
    
    &--big {
        font-size: em(18px);
        
        @media only screen and (min-width: $small-width) {
            font-size: em(14px);
        }
        
        @media only screen and (min-width: $medium-width) {
            font-size: em(16px);
        }
    }
    
    &--left-align {
        text-align: left;
    }s

    &--fluid {
  		width: 100%;
  	}

	&.is-inactive {
        opacity: 0.25;
		cursor: default;
		color: #fff;
		background: rgba(166,166,166,0.5) !important;
	    user-select: none;
	    &:after {
	    	display: none;
	    }
	}

  &__submit {

  	&.profile-account {
  		width: 35%;
  		margin-left: 15px;
  		margin-right: 15px;
  	}

  }

  &__return {
  	float: right;
  }

  &__cancel {
  	text-align: center;

  	&.profile-account {
  		width: 35%;

  	}
  }
}

// Custom buttons

.btn-removal {
	border: 0;
	padding: 4px 8px;
	background-color: $color-warning;
	color: #fff;
	font-size: 12px;
	border-radius: 3px;
	font-weight: $font-weight--bold;
	cursor: pointer;
	@extend %transition-base;

	@media only screen and (min-width: $nohover-width) {
		&:hover {
			background-color: $color-warning-dark;
		}
	}
}
// slick-slider
.icon-arrow {
		width: 60px;
		height: 45px;
		background-repeat: no-repeat;
		display: block;
        background-image: url('../images/icon-arrow.svg');
        background-size: 100%;
         @extend %transition-base;

	&--right {
		-webkit-transform:scaleX(-1);
        -moz-transform:scaleX(-1);
        -ms-transform:scaleX(-1);
        -o-transform:scaleX(-1);
    	transform:scaleX(-1);
	}
    &:hover{
         background-image: url('../images/k-kobrex-partial-white.svg');
         @extend %transition-base;
        
    }
}
