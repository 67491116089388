.page-warning {
    padding: 60px;
    text-align: center;
    h1{
        color: $shark;
        padding-bottom: 20px;
        font-size: 45px;
    }
    p{
        color: $shark;
        font-family: $font-family--secondary;
    }
	&__404 {

	}
    &__403 {

    }
}