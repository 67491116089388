.brands {
    .container-brand {
        height: 110px;
        background-color: $white;
        padding: 0;
        border: 1px solid #cccecd;
          @media screen and (min-width: $small-width) {
             margin: 5px;
             border: 0;
         }
         @media screen and (min-width: $medium-width) {
            margin: 10px;
         }
        .brand-item {
            height: 110px;
            margin: auto;
            background-size: contain;
            -moz-transform: scale(0.8);
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
            background-position: center;
            background-repeat: no-repeat;
            @extend %transition-xxslow; 
            &:hover {
                @media screen and (min-width: $medium-width) {
                    cursor: pointer;
                    -moz-transform: scale(0.95);
                    -webkit-transform: scale(0.95);
                    transform: scale(0.95);
                }
            }
        }
    }
    .container-letter-separator {
        position: relative;
        height: 110px;
        background-color: $gray-light;
        border: 1px solid #cccecd;
         @media screen and (min-width: $small-width) {
             margin: 5px;
         }
        @media screen and (min-width: $medium-width) {
            margin: 10px;
         }
         .letter-separator {
            left: 0;
            right: 0;
            position: absolute;
            text-align: center;
            padding: 5px 0;
            color: $shark;
            font-size: 85px;
            font-weight: bold;
        }
    }
    .paginator {
        display: block;
        margin: 15px auto;
        .page {
            float: left;
            padding: 5px;
            cursor: pointer;
            &.selected {
                cursor: default;
                font-weight: bold;
                font-size: 20px;
                padding-top: 2px;
                padding-bottom: 0;
            }
        }
        .separator {
            float: left;
            padding: 5px 10px;
            color: $white;
        }
    }
}


.page-template-tpl-brands-php{
    .brands {
        .container-brand {
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        top: -20px;
                    }
        }
    }
}
