    .container-partial {
        padding: 10px;

        @media screen and (min-width: $small-width) {
            padding: 60px;
        }

        &--best-sellers {
            background-color: $white;
            padding-top: 60px;
            border-top: 10px solid $crimson;
             position: relative;
            @media screen and (min-width: $small-width) {
                padding-top: 200px;
                top: 0;
            }
             @media screen and (min-width: $medium-width) {
                padding-top: 150px; 
            }
            .container-brand-part-type {
                text-align: center;
                border: 1px solid $gray-light;
                border-right: 0;
                border-bottom: 0;
                padding: 40px;
                 .container-img {
                    height: 150px;
                }
               
                img {
                    max-width: 100%;
                    display: block;
                    margin: auto;
                    max-height: 150px;

                    &.brand-part-type-logo {
                        max-height: 52px;
                    }
                }
                &:hover {
                    cursor: pointer;
                }
            }
            h2 {
                 @media screen and (min-width: $medium-width) {
                    padding-top: 40px;
                }
            }
        }
        &--kobrex-way {
             padding-top: 60px;
            background-color: $white;
             .container-kobrex-way-icon {
                text-align: center;
                margin-bottom: 60px;
            }
            .btn{
                @media screen and (min-width: $small-width) {
                    margin-top: 10px;
                }
                @media screen and (min-width: $medium-width) {
                    margin-top: 20px;
                }
            }
            input[type=email] {
                padding: 12px 10px!important;
            }
            input[type=submit] {
                font-size: 25px;
                padding: 6px 10px;
            }
            h4 {
                letter-spacing: -1px;
            }
            img {
                display: block;
                margin: auto;
                margin-top: 45px;
                max-width: 100%;
                max-height: 120px;
            }
            input[type=text]{
                width: 100%;
                @media screen and (min-width: $small-width) {
                    padding: 12px 8px;
                    margin-top: 10px;
                }
                @media screen and (min-width: $medium-width) {
                    width: 50%;
                    margin-top: 20px;
                    margin-right: 5px;
                } 
            }
            .msg-success, .msg-error{
                font-family: $font-family--secondary; 
                font-size: 14px;
            }

        }
         &--help{
            background-color: $shark;
            position: absolute;
            width:100%;
            padding: 10px;
            z-index:400;
            color: #fff;
            padding: 20px;
            display:none;
            top: 150px;
            position: absolute;
            padding-top: 10px;
             @media screen and (min-width: $small-width) {
                top: 45px;
                width: 250px; 
                height: auto;
            }
            .btn {
                width: 100%;
            }
            .button-back-menu{
                display:block;
                float: right;
                bottom: 5px;
                position: relative;
            }
            .fa-arrow-circle-o-left {
                font-size: 25px;
                vertical-align: middle;
                 @media screen and (min-width: $small-width) {
                    display: none;
                }
            }
            .close-help{
                display: none;
                font-style: normal;
                @media screen and (min-width: $small-width) {
                    display: block;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    right: 0;
                }
                &:before{
                    font-size: 30px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
            .text:first-of-type {
                padding-top: 10px;
            }
            .title {
                font-size: 20px;
               //border-bottom: 1px solid #878787;
            }
        }
        &--my-garage{
            background-color: $shark;
            position: absolute;
            width:100%;
            padding: 10px;
            z-index:400;
            color: #fff;
            padding: 20px;
            display:none;
            top: 150px;
            position: absolute;
            padding-top: 10px;
             @media screen and (min-width: $small-width) {
                top: 45px;
                width: 250px; 
                height: auto;
            }
            .addvehicule{
                width: 100%;
                margin-top: 10px;
            }
            .add-a-vehicule{
                margin-top: 15px;
            }
            .button-back-menu{
                display:block;
                float: right;
                bottom: 5px;
                position: relative;
            }
            .fa{ 
                font-size:16px;
            }
            .fa-arrow-circle-o-left{
                font-size:25px;
                vertical-align: middle;
                 @media screen and (min-width: $small-width) {
                    display: none;
                }
            }
            .close-my-garage{
                display: none;
                font-style: normal;
                @media screen and (min-width: $small-width) {
                    display: block;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    right: 0;
                }
                &:before{
                    font-size: 30px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
            .removevehicule {
                float:right;
            }
            select, .select2-selection--single {
                background-color: $white;
                width:100%;
                margin-bottom: 20px;
                color: $gray-base;
                font-family: $font-family--secondary;
                font-size: 13px;
                padding: 16px 10px;
                height: inherit;
                 @media screen and (min-width: $small-width) {
                     padding: 8px 10px;
                    margin-bottom: 5px;
                }
            }
            .text-my-garage {
                margin-bottom:10px;
            }
             .title {
                font-size: 20px;
            } 
          
            .vehicules {
                font-family: $font-family--secondary;
                font-size: 13px;
                line-height: 20px;
                 padding-top: 10px;
                border-top: 1px solid $gray-base;
                .vehicule-data:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
                .vehiculerow {
                    margin-bottom: 5px;
                    font-size: 14px;
                    @media screen and (min-width: $small-width) {
                        margin-bottom: 0;
                        font-size: 12px;
                    }


                }
            }

           
        }
        &--latest-promo {
            text-align: center;
            background-image: url('../images/partial-latest-promo-bg.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            button {
                bottom: initial;
                background-color: transparent;
                &.slick-arrow {
                    position: absolute;
                    top: 45%;
                    cursor: pointer;
                    outline: 0;
                    display: none!important;
                    @media screen and (min-width: $small-width) {
                        display: block!important;
                    }
                  
                }
                &.slick-prev {
                    left: -70px;
                }
                &.slick-next {
                    right: -70px;
                    transform: scaleX(-1);
                }
            }
            .container-promo img {
                max-width: 100%;
                padding: 10px;
            }
            .description{
                color: $white;
            }
        }
        &--order-detail{
            background-color: $white;
            padding: 60px 0;
            font-family: $font-family--secondary;
            h2{
                color: $black;
                font-family: $font-family--primary; 
            }
            .item-labels{
                border-bottom: 1px solid $gray-light;
                font-weight: bold;
                text-align: center;
                .item-label{
                    padding: 10px;
                }
            }
            .item{
                @media screen and (min-width: $small-width) {
                    font-size: 14px;
                }
                @media screen and (min-width: $medium-width) {
                    font-size: 16px;
                }
            }
            .link-back{
                text-align: center;
                @media screen and (min-width: $small-width) {
                    text-align: right;
                }
            }
            .order-detail{
                .row{
                    padding: 10px;
                }
                .border-bottom{
                     @media screen and (min-width: $small-width) {
                        border-bottom: 1px solid $gray-xlight;
                     }
                }
                &__label{
                    text-align: center;
                    @media screen and (min-width: $medium-width) {
                        text-align: right;
                    }
                }
                 &__info{
                    text-align: center;
                    @media screen and (min-width: $medium-width) {
                        text-align: left;
                    }
                }
            }
        }
        &--order-history{
            background-color: $white;
            padding: 60px 0;
            font-family: $font-family--secondary; 
           
            .dataTables_wrapper{
                .dataTables_paginate{
                    text-align: center;
                    float: none;
                    .paginate_button{
                        &.current{
                        border: 0!important;
                        background: transparent!important;
                        font-weight: bold;
                        margin-top: 20px;
                        }
                        &.previous, &.next{
                           color: transparent!important; 
                           display: none;
                        }
                        &.previous:not(.disabled), &.next:not(.disabled), &.previous:not(.disabled):hover, &.next:not(.disabled):hover{
                            width: 30px;
                            background-repeat: no-repeat;
                            background-image: url(/dist/images/icon-arrow.svg);
                            background-size: 100%;
                            display: inline-block;
                            background-position: center;

                            &:hover{
                                cursor: pointer;
                            }
                        }
                         &.next{
                             transform:scaleX(-1);
                         }
                    }
                    
                }
            }
            .link-back{
                text-align: center;
                @media screen and (min-width: $small-width) {
                    text-align: right;
                }
            }
            .order-history--table{
                font-size: 14px;
                 @media screen and (min-width: $small-width) {
                    font-size: 16px;
                 }
                &.no-footer{
                    border-bottom: 1px solid $gray-light;
                }
                th, tr{
                    border-bottom: 1px solid $gray-light;
                }
             
            }
           
        }
        &--our-brands {
            overflow: hidden;
            background-image: url('../images/partial-our-brands-bg.jpg');
            background-position: -600px -200px;
            background-repeat: no-repeat;
            background-color: $white;
            border-top: 1px solid $gray-light;
            padding-bottom: 20px;
            .container-brands {
                margin-left: 0;
                margin-right: 0;
            }

            .container-logo {
                text-align: center;
                padding: 5px;
                min-height: 100px;
                img {
                    width: 90%;
                    @extend %transition-base;
                    &:hover {
                        @media screen and (min-width: $medium-width) {
                            width: 100%;
                        }
                       
                    }
                }
            }
        }
        h2 {
            text-align: center;
            font-family: $font-family--primary;
            color: $color-primary;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 3px;
            padding-bottom: 20px;
            font-size: 40px;
            @media screen and (min-width: $medium-width) {
                font-size: 50px;
                padding-bottom: 60px; 
            }
            .color-secondary {
                color: $color-secondary;
            }
          
        }
        h3 {
            text-align: center;
            font-family: $font-family--secondary;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            min-height: 35px; 
            margin-top:10px;
            margin-bottom: 10px;
        }
        h4 {
            color: $color-secondary;
            font-size: 22px;
            text-transform: uppercase;
        }
        &--search-engine{
            h4{
                font-size: 24px; 
                @media screen and (min-width: $small-width) {
                    font-size: 36px; 
                }
            }
        }
        &--subscription{
            background-color: $shark;
            position: absolute;
            width:100%;
            padding: 10px;
            z-index:400;
            color: #fff;
            padding: 20px;
            display:none;
            top: 150px;
            position: absolute;
            padding-top: 10px; 
             @media screen and (min-width: $small-width) {
                top: 45px;
                width: 250px; 
                height: auto;
            }
            .button-back-menu{
                float: right;
                position: relative;
                bottom: 5px;
            }
            .close-subscription{
                display: none;
                @media screen and (min-width: $small-width) {
                    display: block;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    right: 0;
                }
                &:before{
                    font-size: 30px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
             .fa-arrow-circle-o-left{
                font-size:25px;
                vertical-align: middle;
                 @media screen and (min-width: $small-width) {
                    display: none;
                }
            }

            .btn{
                width: 100%;
            }
             .fa-close{
                position: relative;
                 @extend %transition-base;
                 display: none;
                  @media screen and (min-width: $small-width) {
                    display: block;
                  }
                &:hover{
                    cursor: pointer;
                    color: $crimson;
                    @extend %transition-base;
                }
            }

            p{
                font-family: $font-family--secondary;
                font-size: 13px;
                a{
                    font-weight: bold;
                    color: $crimson;
                }
                &.text{
                    border-top: 1px solid $gray-base;
                    padding-top: 10px; 
                }
            }
            .title{
                font-size: 20px;
            }

        }
        &--tools-and-accessories {
            z-index: 99;
            position: absolute;
            background-color: $shark;
            width: 100%;
            display: none;
            background-image: url(/dist/images/k-kobrex-gray.svg);
            background-repeat: no-repeat;
            background-size: contain;
            border-bottom: 10px solid $crimson;
            padding-bottom: 20px;
            top: 150px;
             @media screen and (min-width: $small-width) {
                top: 200px;
            }
            @media screen and (min-width: $medium-width) {
                top: inherit;
            }
             &.active{
                display: block;
            }
            button{
                margin-top: 0;
                text-transform: uppercase; 
                padding: 10px;
                &.btn{
                    width: 100%;
                    @media screen and (min-width: $small-width) {
                        width: inherit;
                        float: right;
                    }
                }
            }
            .container-search-field{
                @media screen and (min-width: $medium-width) {
                     float: right;
                }
            }
            .container-search-full{
                background-image: url('/dist/images/gear.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;
                height: 165px;
                border-top: 5px solid #1e1e20;
                text-align: center;
                @media screen and (min-width: $small-width) {
                    text-align: left;
                    
                }
                @media screen and (min-width: $medium-width) {
                    border-left: 10px solid #1e1e20;
                    border-top: 0;
                    height: 135px;
                }
                button{
                    width: 130px;
                }
                .subtitle:nth-of-type(2){
                    font-size: 24px;
                }
            }
            .container-search-keyword{
                 padding: 11px;
                .subtitle{
                    padding-top: 10px;
                }
            }
            .container-search-tool-keyword{
                background-color: #484848; 
                border: 10px solid #1e1e20;
                margin: 15px 0 50px;
            }
            .container-subcategory{
                display: none;
                font-family: $font-family--secondary;
                font-size: 14px;
                margin-top: 20px;
                a{
                    color: $gray-base;
                }
                .subcategory{
                    margin-bottom: 5px;
                    font-weight: bold;
                    color: $white;
                }
            }
            .divider{
                border-top: 5px solid #1e1e20;
                margin: 5px 0;
            }
            .form-group{
                text-align: center;
                 @media screen and (min-width: $small-width) {
                    text-align: left;
                 }
            }
            h2{
                color: $white;
                text-transform: uppercase;
                font-size: 40px;
            }
            input[type=text]{
                background-color: $white;
                padding: 10px;
                width: 100%;
                margin-right: 10px;
                font-family: $font-family--secondary;
                font-size: 14px;
                margin-bottom: 10px;
                @media screen and (min-width: $small-width) {
                    width: 220px;
                }
            }
            .input--keyword{
               background-color: $white; 
               width: 100%;
               font-family: $font-family--secondary; 
               font-size: 14px;
               @media screen and (min-width: $medium-width) {
                    width: 50%;
                }
            }
            &__list{
            text-align: center;
                @media screen and (min-width: $medium-width) {
                    text-align: left;
                }
            }
            .subtitle{
                color: $white;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 15px;
                display: inline-block;
                font-family: $font-family--secondary;
            }
            .tools-category{
                font-family: $font-family--secondary;
                border-bottom: 1px solid $gray-base;
                padding: 8px;
                &:last-of-type{
                    border: 0;
                }
                &:hover{
                    cursor: pointer;
                }
            }
            .title{
                color: $white; 
                
            }
            .tools-category{
                padding: 10px;
                font-family: $font-family--secondary; 
                @extend %transition-base;
                font-size: 14px;
                &:not(:last-of-type){
                    border-bottom: 1px solid $gray-base;
                }
                &.active{
                    background-color: $gray-dark;
                    color: $white; 
                }
                &:after{
                    content: "";
                    float: right;
                }
                &:hover{
                    background-color: $gray-dark; 
                    color: $white;
                    cursor: pointer;
                    @extend %transition-base;
                }
            }
            ul{
               list-style: none;
               li{
                    color: $white;
                    font-family: $font-family--secondary;
                    font-size: 14px; 
                   h5{
                        padding-top: 10px;
                   }
               }
            }
          
        
        }
    
    }