// ==========================================================================
// Product lines - refactored
// ==========================================================================

.sort_alpha {
    width: 90%;
    margin: 0 auto;
    ul {
        text-align: center;

        li {
            padding: 0 0 0 10px;
            display: inline-block;
            margin: 0 auto;
            
            &.active {
                color: $color-primary;
                font-weight: $font-weight--bold;
                text-transform: uppercase;
                font-size: 18px;
            }

            &.disabled {
                opacity: 0.4;
            }
        }

        li:first-child{
            padding: 0;
        }
    }
}

.product-line {
    box-shadow:         2px 2px 17px 0px rgba(50, 50, 50, 0);
    cursor: pointer;
    @extend %transition-base;

    @media screen and (min-width: $nohover-width) {
        &:hover {
            box-shadow:         2px 2px 17px 0px rgba(50, 50, 50, 0.5);
        }        
    }

    &.article {
        @extend %clearfix;
        margin: 20px 0;
        border: 1px solid $gray-base;
    }

    a {
        display: table;
        width: 100%;
        height: 100%;
        @extend %clearfix;
    }

    .product-line-logo {
        
        width: 100%;
        border-bottom: 1px solid $gray-base;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 100%;
        // height: $productl-height;
        // line-height: $productl-height;
        text-align: center;

        img {
            max-width: 100%;
            max-height: 65px;
        }

        @media screen and (min-width: 700px){
            display: table-cell;
            vertical-align: middle;
            //float: left;
            width: 150px;
            border-right: 1px solid $gray-base;
            border-bottom: 0;
        }
    }    

    .product-line-content {

        //float: left;
        padding-left: 20px;
        // height: $productl-height;
        padding-top: 20px;
        padding-bottom: 20px;

        @media screen and (min-width: 700px){
            display: table-cell;
            vertical-align: middle;
        }

       h2 {
        font-weight: bold;
        margin-bottom: 0
       }

       p {
        color: black;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
       }
    }
} 

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
