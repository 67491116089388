.container-step3 {
    background-color: white;
    @media screen and (min-width: $medium-width) {
        padding-top: 35px;
    }
    .container-btn--filter {
        background: $crimson;
        text-align: center;
        color: $white;
        padding: 15px;
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        @media screen and (min-width: $medium-width) {
            display: none;
        }
    }
    .container-toggle-noprice {
        font-size: 14px;
        padding: 5px;
        @extend %transition-base;
        @media screen and (min-width: $small-width) {
            background-color: $gray-xlight;
            border: 1px solid $gray-light;
            height: 100px;

        }
         @media screen and (min-width: $medium-width) {
            margin-top: 15px; 
        }
     
        input:checked+.slider {
            background-color: #EB2131;

            &:before {
                -webkit-transform: translateX(26px);
                transform: translateX(26px);
                @extend %transition-base;
            }
        }
        .slider.round {
            border-radius: 34px;
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #313236;
            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: #fff;
                border-radius: 50%;
                @extend %transition-base;
            }
        }
        .switch {
            position: relative;
            display: block;
            width: 60px;
            height: 34px;
            vertical-align: middle;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
        }
        .toggle-button {
            text-align: center;
            font-size: 14px;
            margin-top: 15px;
             @media screen and (min-width: $small-width) {
                margin-top: 15px;
               
             }
            @media screen and (min-width: $medium-width) {
                 font-size: 16px;
            }
            &--text{
                
                padding: 5px;
                font-size: 16px;
                @media screen and (min-width: $medium-width) {
                    top: 50%;
                    position: relative;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .left-side {
        padding: 0;
        display: none;
        opacity: 0;
        width: 100%;
        @extend %transition-base; 
       
        @media screen and (min-width: $medium-width) {
            transform: inherit;
            display: block;
            position: inherit;
            width: 20%; 
            padding: 20px;
            padding-right: 40px;
            opacity: 1;
        }
        &.is-open {
            display: block;
            opacity: 1;
            background-color: $white;
        }
        .container-categories {
            font-family: $font-family--secondary;
            font-size: 13px;
            background-color: $gray-xlight;
            border: 1px solid $gray-light;
            @media screen and (min-width: $small-width) {
                padding: 0 50px;
            }
            @media screen and (min-width: $medium-width) {
                padding: 0;
            }
            .categories {
                clear: both;
                .filter-icon{
                    float: left;
                }
                .filter-name{
                    display: block;
                    padding-left: 15px; 
                }
            }
            > ul > li {
                @media screen and (min-width: $medium-width) {
                    margin: 0 30px;
                }
            }
            ul {
                padding-bottom: 20px;
                li {
                    padding: 10px;
                    list-style: none;
                     @media screen and (min-width: $medium-width) {
                        padding: 4px;
                     }
                }
            }
            > ul > li:not(:last-of-type) {
                border-bottom: 1px solid $gray-light;
                list-style: none;
            }
            .submenu-handle {
                display: block;
                cursor: pointer;
                width: 95%;
                font-weight: bold;
                font-size: 14px;
                text-transform: uppercase;
                color: $shark;
                padding-top: 20px;
                padding-bottom: 10px;
            }
            span,
            i {
                font-style: normal;
            }
        }
        .chk {
            width: 18px;
            height: 18px;
            float: left;
            margin-right: 10px;
        }
        .container-btn--reset {
            padding: 10px 0;
            position: relative;
            text-align: center;
            margin: 0 0 20px;
            background: linear-gradient(0deg, $gray-light 0%, $gray-light 63%, $gray-light 100%); /* w3c */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gray-light', endColorstr='$gray-lighty',GradientType=0 ); /* ie6-9 */
            transform-style: preserve-3d;
            @extend %transition-base;
            display: block;
         
            .reset-icon {
                height: 40px;
                width: 40px;
                background-image: url('/dist/images/icon-reset-red.svg');
                background-repeat: no-repeat;
                background-size: cover;
                margin: auto;
            }
            .reset-text {
                color: $crimson;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
                margin-top: 10px;
            }
        }
        .filter {
            label {
                word-break: break-word;
                display: block;
            }
        }
    }
    .parttype-sticky-header{
        position: fixed;
        top: 0;
        z-index: 99;
        background-color: $crimson!important;
        display: none;
    }
    .right-side {
        padding: 0px;
        @media screen and (min-width: $medium-width) {
            width: 80%;
            padding: 20px;
        }
        .brand-info{
            height: 100%;
        }
        .part {
            padding: 0;
            margin: 20px 0;
            border: 1px solid #d6d6d6;
            background-color: $white;
            position: relative;
            font-family: $font-family--secondary;
            font-size: 14px;
            .part-middle {
                padding: 0;
                background-color: #f8f8f8;
                background-image: url('../images/k-kobrex-partial-light-gray.svg');
                background-position: right -100px top -98px;
                background-repeat: no-repeat;
                background-size: 80%;
                @media screen and (min-width: $small-width) {
                    min-height: 320px;
                }
                .brand-logo {
                    background-color: $white;
                    height: 100%;
                    max-width: 100%;
                    text-align: center;
                    border-left: 1px solid $gray-xlight;
                    
                    @media screen and (min-width: $small-width) {
                        padding: 0;
                        
                        top: 50%;
                        position: relative;
                        transform: translateY(-50%);
                    }
                    img {
                         @media screen and (min-width: $small-width) {
                            top: 50%;
                            width: 90%;
                            position: relative;
                            transform: translateY(-50%);
                        }
                    }
                }
                .container-brand-info {
                    background-color: rgba(20,20,20,.15);
                    padding: 0px;
                    padding-bottom: 0!important;
                    text-align: center;
                    @media screen and (min-width: $small-width) {
                        text-align: left;
                        height: 85px;
                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
                .fits-on {
                    
                    a {
                        color: #EE0000;
                        font-weight: bold; 
                    }
                    .fit{
                        list-style: none;
                    }
                    h4 {
                        padding: 7px 7px 7px 0;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .header-fit{
                        color: $crimson;
                        font-weight: bold;
                    }

                    label {
                        font-weight: bold;
                    }
                    ul {
                        li {
                           
                            padding-top: 3px;
                            ul {
                               
                                margin-left: 15px;
                                margin-top: 0;
                                li a {
                                    color: #878787;
                                    font-weight: normal;
                                    &:hover {
                                        background-color: #969696;
                                        color: #fff;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .container {
                    padding-bottom: 20px;
                }
               
                .part-info {
                    margin-top: 10px;
                     height: 100%;
                     padding-right: 5px;
                     text-align: left;
                    .partnumber {
                        width: 100%;
                        font-size: 18px;
                        color: $crimson;
                        font-family: $font-family--secondary;
                        font-weight: bold;
                    }
                    .partname {
                        word-break: break-word;
                        width: 100%;
                        font-size: 14px;
                        font-weight: bold;
                        font-family: $font-family--secondary;
                        color: $shark;
                        letter-spacing: 2px;
                        @media screen and (min-width: $medium-width) {
                            font-size: 16px;
                        }
                    }
                    .partlabel{
                        color: $gray-dark;
                        font-weight: normal;
                        font-size: 15px; 
                    }
                    .qualifier {
                        width: 100%;
                        padding: 0px 5px;
                        font-size: 18px;
                    }
                }
                .part-spec {
                    padding: 10px 0;
                    font-weight: normal;
                    .infos {
                        color: $shark;
                        .full-width {
                            width: 100%;
                        }
                        h4 {
                            padding: 7px 7px 7px 0px;
                            font-size: 18px;
                            font-weight: bold;
                        }
                        label {
                            font-weight: bold;
                            color: $black;
                            display: block
                        }
                        .note {
                            width: 100%;                            
                            color: $gray-base;
                            margin-bottom: 15px;
                        }
                        .position {
                            color: $gray-base;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
            .part-right{
                text-align: center;
                 .container-price {
                    text-align: center;
                    margin: 20px 0;
                    @media screen and (min-width: $small-width) {
                        margin: 0px;
                        text-align: left;
                    }
                    .call-price{
                        text-align: center;
                         @media screen and (min-width: $medium-width) {
                            text-align: right;
                         }
                    }
                    &:hover{
                        .cost-price{
                            display: block;
                        }
                    }

                }
                .item-added{
                    font-size: 10px;
                    @extend %transition-base;
                }
                .part-quantity {
                    padding-top: 15px;
                    min-height: 80px;
                    max-height: 105px;
                    @media screen and (min-width: $medium-width) {
                        max-height: inherit;
                        
                    }
                    .deposit {
                        color: green;
                    }
                    .unit-price {
                        font-weight: bold;
                        font-size: 40px;
                        color: $crimson;
                        display: block;
                        text-align: center;
                        @media screen and (min-width: $medium-width) {
                            text-align: right;
                       }
                    }
                    .quantity-control {
                        border: 1px solid $gray-light;
                        background-color: $white;
                        text-transform: uppercase;
                        padding-left: 5px;
                        color: $shark;
                        width: 105px;
                        margin: 20px auto; 
                        height: 40px;
                        @media screen and (min-width: $medium-width) {
                             float: right;
                         }
                        input.error {
                            border: 1px solid red;
                            color: red;
                        }
                    }
                    input[type=number] {
                        color: $crimson;
                        font-family: $font-family--secondary;
                        font-weight: bold;
                        font-size: 20px;
                        width: 60px;
                        padding: 5px 10px;
                    }
                    input[type=number]::-webkit-inner-spin-button, 
                    input[type=number]::-webkit-outer-spin-button { 
                        opacity: 1;
                    }
                    .save, .availability,.cost-price {
                        display: block;
                        color: $shark;
                        @media screen and (min-width: $medium-width) {
                            text-align: right;
                        }
                    }

                    .cost-price{
                        display: none;
                    }

                }
            }
        }
        button {
            font-size: 17px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            @media screen and (min-width: $small-width) {
                width: 50%
            }
            @media screen and (min-width: $medium-width) {
                width: 100%;
            }
        }
        .part-overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: block;
            background-color: $black;
            opacity: 0.5;
            z-index: 100;
            transition: all 620ms ease;
            &-message {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: block;
                z-index: 100;
                font-size: 16px;
                opacity: 1;
                .overlay-box {
                    border-radius: 2px;
                    background-color: #fff;
                    height: 100px;
                    max-width: 350px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
                    line-height: 100px;
                    vertical-align: middle;
                    .fa{
                        margin-right: 10px;
                    }
                    .success {
                        color: $shark;
                    }
                    .error {
                        color: $crimson;
                    }
                }
            }
            &-loading {
                position: absolute;
                top: 40%;
                left: 48%;
                display: block;
                z-index: 100;
                font-size: 60px;
                opacity: 1;
                color: #fff;
            }
        }
         .vehicle-specific{
            margin-top: 5px;
            margin-right: 3px;
            margin-bottom: 10px;
            color: $white;
            background-color: $shark;
            padding: 4px;
            border-radius: 3px;
            display: inline-block;
            font-family: $font-family--secondary;
            text-transform: uppercase;
            font-size: 8px;
            width: 60px;
            text-align: center;
            @media screen and (min-width: $small-width) {
                float: right;
                margin-top: 3px;
                width: 80px;
                font-size: 10px;
            }
        }
       
    }
    
    .fa-minus-circle,
    .fa-plus-circle {
        float: left;
        font-size: 25px;
    }
    .part-left {
        background-color: $white;
        text-align: center;
        padding: 0; 
        @media screen and (min-width: $small-width) {
            max-height: inherit;
        }
        .part-thumb {
            display: block;
            width: 100%;
            text-align: center;
            padding: 10px;
            img {
                max-width: 100%;
                max-height: 250px;
            }
            .image-gallery{
                  list-style: none;
            }

        }     
    }
    .parttype-header {
        width: 100%;
        padding: 10px;
        vertical-align: middle;
        font-size: 23px;
        font-weight: normal;
        background-color: $shark;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        font-family: $font-family--secondary;
        &:hover {
            cursor: pointer;
        }
    }
    h3 {
        color: $white;
        background-color: $gray-light;
        padding: 15px;
        margin-bottom: 10px;
    }
    h4 {
        padding: 10px;
        color: $shark;
    }
}

pre{
    overflow: inherit;
}

.scroll-to-top{
    background-color: $shark;
    width: 50px;
    height: 50px;
    color: $white;
    font-size: 20px;
    text-align: center;
    position: fixed;
    right: 80px;
    bottom: 10px;
    padding-top: 13px;
    display: none;
    z-index: 50;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0,0,0,.5); 
    @extend %transition-base;
     @media screen and (min-width: $medium-width) {
        right: 185px;
     }
    &:hover{
        cursor: pointer;
        background-color: $crimson; 
        @extend %transition-base;
    }
}
.title--step__text{
    .vehicle-specific{
        color: $white;
        background-color: $shark;
        padding: 4px;
        border-radius: 3px;
        display: inline-block;
        font-size: 7px;
        font-family: $font-family--secondary;
        text-transform: uppercase;
        width: 30px;
        text-align: center;
    }
}
     