.sidebar {
	background-color: $gray-base;
	padding-top: 30px;
	padding-bottom: 30px;
	//margin-left: -$grid-gutter-width;

	.sidebar{
		padding:0;
	}

	.form-wrap .form-group label { margin: 0; }

	.search_vehicle {
		@extend %clearfix;
		margin: 0 auto;

		@media screen and (min-width: 1024px) {
			width: 100%;
		}
	}

	.form-wrap {
		color: $white;

		label {
			text-transform: uppercase;
			font-size: small;
			letter-spacing: 0.1em;
			padding: 0.5em 0;
		}
	}

	.h3 {
		font-size: em(16px);
		text-transform: uppercase;
	    letter-spacing: .1em;
	    margin-bottom: 0;
	    color: $white;
	    padding: .5em 0;

	    &.categorie {
	    	
	    }
	}

	.search-control {
		margin-bottom: 20px;
	}

	.application-search-wrap,
	.partnumbers-search-wrap {
		background-color: lighten($gray-base, 15%);
		padding: 12px;
		border-radius: 6px;
	}
}

