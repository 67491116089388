.help-container {
	position: relative;
	

	//display: none;
	
	padding-top: 0;
	padding-bottom: 0;
	opacity: 0;

	p { color: $base__font-color; }

	@extend %transition-base;

	&.is-open {
		background-color: $gray-xlight;
		border-top: 20px solid $color-primary;
		//display: block;
		padding-top: 40px;
		padding-bottom: 40px;
		opacity: 1;
		box-shadow:         1px 1px 14px 0px rgba(50, 50, 50, 0.75);

		.wysiwyg-container, .fa {
			display: block;
		}
	}
	.wysiwyg-container, .fa {
		display: none;
	}

	.wysiwyg-container {
		@extend .container;
		background-color: $gray-xlight;
	}
	.fa {
		position: absolute;
	    top: 10px; right: 10px;
	    font-size: 1.7em;
	    cursor: pointer;
	    @media screen and (min-width: $medium-width) {
	    	right: 20px;
	    }
	}



}