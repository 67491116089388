 .container-partial {
    &--step-breadcrumb {
        padding: 5px!important;
        background-color: $gray-xlight;
        @media screen and (min-width: $medium-width) {
            border-left: $crimson solid 15px;
        }
        
        .button-save-vehicle {
            position: relative;
            text-align: center;
            color: $white;
            font-family: $font-family--secondary;
            font-size: 16px;
            padding-top: 10px;
            font-weight: bold; 
            z-index: 1;
            top: 37px;
            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
            @media screen and (min-width: $small-width) {
                position: absolute;
                bottom: 5px;
                left: 0;
                right: 0;
                top: inherit;
                font-size: 12px;
             }
              @media screen and (min-width: $medium-width) {
                color: $shark;
              }
            @media screen and (min-width: 1250px) {
                margin-top: 10px;
                text-align: right;
                -webkit-transform: skew(50deg);
                transform: skew(50deg);
                position: relative;
                bottom: 0;
            }
            i {
                padding-right: 5px;
            }
        }
        .breadcrumb {
            overflow: hidden;
            text-align: center;
            padding: 0;
            z-index: 2;
            position: relative;
            padding-bottom: 20px;
            @media screen and (min-width: 1250px) {
                margin: 30px 0 20px;
            }

            .container-breadcrumb-line{
                display: none;
                z-index: -1;
                position: relative;
                left: 177px;
                top: 33px;
                @media screen and (min-width: 1250px) {
                    display: block;
                }

                hr {
                   height: 4px;
                    background: $crimson;
                    margin: auto;
                    width: 100%;
                    float: left;
                    
                   &.dotted{
                        border-top:4px dashed $gray-light;
                        background: transparent;
                    }
                }
            }


            .container-step {
                margin-bottom: 2px;
                padding: 2px;
              
                
                @media screen and (min-width: 1250px) {
                    padding: 0 20px;
                    margin-bottom: 5px;
                    -webkit-transform: skew(-50deg);
                    transform: skew(-50deg);
                    left: 80px;
                    position: relative;
                }
               
                 &.disabled {
                    .step-click-zone:hover{
                        cursor: inherit;
                    }
                    .step {
                        background-color: #c9c9c9;
                    }
                    .step-number {
                        background-color: $gray-base;
                    }
                }
                &.active {
                    .step-number {
                        background-color: $crimson;
                    }
                    .step-click-zone:hover{
                        cursor: inherit;
                    }
                    .dotted{
                        border-top:4px dashed $crimson;
                    }    
                 } 
                 &.next {
                     .container-content-step:hover{
                        cursor: pointer;
                    }
                    .step{
                        background-color: $crimson;
                    }
                    .step-number{
                        background-color: $shark!important; 
                    } 
                }
            }
            .container-step-save{
                background-color: $crimson;
                height: 50px;
            }
            .step-click-zone{
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 2;
                @media screen and (min-width: $medium-width) {
                    width: 58%;
                    -webkit-transform: skew(50deg);
                    transform: skew(50deg);
                }
                &:hover{ 
                    cursor: pointer;
                }
            }
            .step {
                background-color: $shark;
                color: $white;
                padding: 5px;
                height: 80px;
                @media screen and (min-width: $small-width) {
                    height: 140px;
                }
                @media screen and (min-width: 1250px) {
                    padding: 5px;
                    width: 220px;
                    height: 70px;
                }
                .container-content-step{
                    top: 50%;
                    position: relative;
                    transform: translateY(-50%);
                    @media screen and (min-width: $small-width) {
                        position: absolute;
                        bottom: 25px;
                        top:inherit;
                        transform: inherit;
                        left: 0;
                        right: 0;
                    }
                    @media screen and (min-width: 1250px) {
                        position: relative;
                        transform: translateY(-50%);
                        top: 50%;
                    }
                 
                    hr{
                        height: 1px;
                        background: rgba(255,255,255,.5);
                        margin: auto;
                        width: 50%;
                        margin-top: 5px;

                        @media screen and (min-width: $small-width) { 
                            width: 80%;
                        }
                    }

                }
                &-number {
                    display: inline-block;
                    width: 20px;
                    margin-right: 0;
                    background-color: $gray-base;
                    color: $white;
                    font-size: 20px;
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                    float: left;
                    position: relative;
                    z-index: 2;
                    position: absolute;
                    left: 10px;
                    bottom: 10px;
                    line-height: 20px;
                    @extend %transition-base;
                    @media screen and (min-width: $small-width) {
                        margin: auto;
                        left: 0;
                        right: 0;
                        bottom: inherit;
                        font-size: 15px;
                        line-height: 15px;
                        width: 45px;
                        height: 45px;
                        margin-top: 5px;
                    }
                     @media screen and (min-width: 1250px) {
                       left: -40px;
                       right: inherit;
                       margin: 0;
                       bottom: -1px;
                       font-size: 20px;
                        width: 55px;
                        height: 55px;
                        line-height: 20px;
                        -webkit-transform: skew(50deg);
                        transform: skew(50deg);
                        width: 65px;
                        height: 65px;
                    }
                    &-hover{
                        background-color: $crimson;
                        @extend %transition-base;
                    }
                    
                }
            }
            .fa-shopping-cart{
                @media screen and (min-width: $medium-width) {
                    padding-top: 6px; 
                }   
             }
            &--text-step {
                overflow: hidden;
                padding-top: 5px;
                min-height: 20px;

                @media screen and (min-width: 1250px) {
                    -webkit-transform: skew(50deg);
                    transform: skew(50deg);
                }
                &-number {
                    font-weight: bold;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    position: relative;
                    top: 55%;
                    font-size: 16px;
                   
                }
            }
            &--subtitle-step {
                display: block;
                font-weight: bold;
                text-transform: uppercase;
                font-family: $font-family--secondary;
                font-size: 13px;
                 @media screen and (min-width: $medium-width) {
                    font-size: 15x;
                }
                @media screen and (min-width: 1250px) {
                    -webkit-transform: skew(50deg);
                    transform: skew(50deg);
                }
            }
        }
        
    }
}  

.save-overlay{
    position: absolute;
    left: 0;
    width: 100%; 
    height: 235px;
    background-color: rgba(166, 166, 166, .5);
    z-index: 4;
    -webkit-transition: all 620ms ease;
    transition: all 620ms ease;
   &-loading {
        position: absolute;
        text-align: center;
        display: block;
        z-index: 100;
        font-size: 60px;
        opacity: 1;
        color: #fff;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
    }
    &-message {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 100;
        opacity: 1;

        .overlay-box {
            top: 50%;
            transform: translateY(-50%);
            background-color: #fff;
            min-height: 100px;
            width: 350px;
            margin: auto;
            font-family: $font-family--secondary;
            vertical-align: middle;
            position: relative;
            padding: 15px;
            border-radius: 2px;
            .success {
                color: #313236;
                text-align: center;
            }
            .error {
                color: #EB2131;
                text-align: justify;
            }
        }
    }
}  

