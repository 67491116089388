.container-partial { 
    &--your-promo { 
       
        padding-bottom: 20px;
        @media screen and (min-width: $small-width) {
            padding-top: inherit;
        }
        button {
            display: none!important;
        }
        .container-promo img {
            width: 100%;
            max-width: 320px;
            padding: 10px;
        }
    } 
}
.container-promo{
    .btn{
        margin-top: 0;
    }
    input.email {
        display: inline-block;
        padding: 10px;
        background-color: #FFF;
        border-radius: 0;
        margin: 0;
        width: 50%;
        min-width: 100px;
        font-family: $font-family--secondary;
        @media screen and (min-width: $small-width) {
            width: 60%;
        }
    }
    .promo-image{
       width: 100%; 
    }
    strong{
        color: $color-primary;
    }
    .subscribe-newsletter{
        background-color: $gray-light;
    }
    .subtitle{
        font-weight: bold;
        color: $color-secondary;
        font-family: $font-family--secondary;
    }
    .text{
        font-family: $font-family--secondary;
        color: $color-secondary;
        font-size: 12px;
    }
    .text-brands{
        color: $color-primary;
        font-weight: bold;
        font-family: $font-family--secondary;
    }
    .title{
        color: $color-secondary;
        font-weight: 700;
        font-size: 40px;
       
    }
}
