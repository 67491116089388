.container-banner {
  .banner{
    @media screen and (min-width: $medium-width) {
      height: 627px;
    }
  }
  .banner--light-vehicles, .banner--heavy-trucks{
    .slick-arrow{
       position: absolute;
        top: 45%;
        cursor: pointer;
        outline: 0;
        z-index: 2;
      &.slick-next{
        right: 10px;
       @media screen and (min-width: $medium-width) {
          right: 70px;
         }
       -webkit-transform: scaleX(-1); 
        transform: scaleX(-1);
      }
      &.slick-prev{
        left: 10px;
        @media screen and (min-width: $medium-width) {
          left: 70px;
         }
      }
    }
    .slick-list{
      width: 100%;
    }
  }
	&--home, &--home-prev{
		background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height:150px;
    display: block;
   
     @media screen and (min-width: $small-width) {
        height:480px;
        
     }
      @media screen and (min-width: $medium-width) {
        height:625px;
      }
    .banner__caption {
      top: 80%;
      transform: translateY(-60%);
      position: relative;
      @media screen and (min-width: $medium-width) {
         top: 50%;
      }
      .caption{
        position: relative;
        font-size: 60px;
        line-height:70px;
        text-shadow: 2px 2px 12px #000;
        color:$white;
        font-weight: inherit;
        text-transform: uppercase;
        text-align: center;
        @media screen and (min-width: $medium-width) {
          text-align: right;
        }
       }
    }
  }
  &--page{
    background-size: cover;
    background-position: center; 
    height:342px;
      @media screen and (min-width: $small-width) {
        //height:342px;
      }
  }
 
  &--step2 {
      background-image: url(../images/partial-latest-promo-bg.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      height:auto;
      padding-top: 30px;
      .banner__title {
          font-size: 30px;
          padding-bottom: 15px;
          text-align: center;
          font-family: $font-family--primary;
          color: $crimson;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 3px;
          @media screen and (min-width: $small-width) {
            font-size: 50px;
          }
       
      }
      .container-personas{
          display: none;
      }
  }

}  	
