$sl-lightbox-transition: opacity 0.5s ease !default
$sl-caption-margin: 10px 0 0 !default
.slick-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-transition: $sl-lightbox-transition;
  transition: $sl-lightbox-transition;
  .slick-loading .slick-list {
    background-color: transparent;
  }
  .slick-prev{
    left: 0;
    top: 50%;
    transform: translateY(-50%); 
    position:absolute;
    z-index:100;
     @media screen and (min-width: $small-width) {  
      left: 25px;
    }
    & > img {
        max-width:50px;
    }
  }
  .slick-next {
    right: 0;
    top: 50%;
    position:absolute;
    z-index:100;
     -moz-transform: scaleX(-1) translateY(-50%); 
     -o-transform: scaleX(-1) translateY(-50%);
    -webkit-transform: scaleX(-1) translateY(-50%);
     transform: scaleX(-1)  translateY(-50%);
    filter: FlipH;
    @media screen and (min-width: $small-width) {  
      right: 25px;
    }
    -ms-filter: "FlipH";
           max-width:50px;

    & > img {
        max-width:50px;
    }
    :hover{
        cursor: pointer;
    }

  }
}
.slick-lightbox-hide {
  opacity: 0;
  &.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.slick-lightbox-hide-init {
  position: absolute;
  top: -9999px;
  opacity: 0;
  &.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.slick-lightbox-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.slick-lightbox-slick-item {
  text-align: center;
  overflow: hidden;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
  .slick-caption-bottom &
    .slick-lightbox-slick-item
      .slick-lightbox-slick-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
      }
  .slick-caption-dynamic &
    .slick-lightbox-slick-item
      .slick-lightbox-slick-caption {
        display: block;
        text-align: center;
      }
}
.slick-lightbox-slick-item-inner {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-img {
  margin: 0 auto;
  display: block;
  max-width: 78%;
  max-height: 78%;
  @media screen and (min-width: $small-width) {  
    max-width: 90%;
    max-height: 90%;
  }
}

.slick-lightbox-slick-caption {
  margin: $sl-caption-margin;
  color: white;
  padding-top:15px;
  display:block;
}

// used slick's button css
.slick-lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
  &:focus {
    outline: none;
  }
  &:before {
    font-family: "slick";
    font-size: 50px;
    line-height: 1;
    color: white;
    opacity: 0.85;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '×';
  }
}