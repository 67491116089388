main {
   top: 150px;
   position: relative;
   margin-bottom: 144px;
    @media screen and (min-width: $small-width) {
        top: inherit;
         margin-bottom: inherit;
    }
    .container-home--content{
        margin-top: 250px;
        @media screen and (min-width: $small-width) {
            margin-top: 0;
            margin-top: 100px;
        }
         @media screen and (min-width: $medium-width) {
            margin-top: 0;
            
        }

    }
    .fullwidth {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0; 
    }
     .mobile-overlay{
        background-color: rgba(0,0,0,.7);
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;
    }
    
}
