.container-news{
    
    &__article {
       
      .container-link-back{
            padding-bottom: 20px;
            a{
                background: url(../images/icon-arrow.svg) no-repeat;
                background-size: 25px;
                padding-left: 30px;
                background-position: center left;
                color: $crimson;
            }
      }
      .wysiwyg-content{
            font-color: $shark;
            margin: 50px 0;
        }
    }

    
    &--list {
        article {
            padding-bottom: 20px;
            border-bottom: 1px solid $gray-light;
            margin-bottom: 20px;
        }
        .wysiwyg-content{
            font-color: $shark;
            margin: 50px 0;
        }
    }
    
} 