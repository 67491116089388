.divider {
	display: block;
	width: 100%;
	height: 1px;
	background-color: $gray-light;
	margin-top: 20px;
	margin-bottom: 20px;

	// Modifiers

	&--center {
		margin-left: auto;
		margin-right: auto; }

	&--dark {
		background-color: $gray-dark; }

	&--light {
		background-color: $white; }

	&--w90 { width: 90%; }
	&--w80 { width: 80%; }
	&--w70 { width: 70%; }
	&--w60 { width: 60%; }
	&--w50 { width: 50%; }
}

.bordered-r {
	border-right: 1px solid $gray-light;
}
.bordered-l{
	border-left: 1px solid $gray-light;
}