.site-header {
    min-height: 90px;
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: white;
    height: 149px;
    top: 0;
    @extend %transition-base; 
    box-shadow: 0 0px 20px #000;
    @media screen and (min-width: $small-width) {
        height: 200px;
        position: static;
        box-shadow: 0 0 0;
    }
    @media screen and (min-width: $medium-width) {
        width: auto;
        height: 170px;
        position: relative;
        box-shadow: none;
    }
    
    .divider-menu {
         @media screen and (min-width: $small-width) {
            border-left: $gray-base 1px solid;
            padding-left: 20px!important;
         }
    }
    .fa {
        font-size: 35px;
        margin-left: 5px;
        @extend %transition-base;
        &:hover {
            color: $color-primary;
            cursor: pointer;
            @extend %transition-base;
        }
        &-shopping-cart {
            font-size: 16px; 
        }
       
    }
    li {
        display: inline-block;
        color: $black; 
        font-family: $font-family--primary;
        font-size: 14px;
        @media screen and (min-width: $medium-width) {
            font-size: 16px;
        }
    }
    .link-help {
        &:hover {
            cursor: pointer;
        }
    }
    .container-menu-bar{
        display: none;
        @media screen and (min-width: $small-width) {
            background-color: $crimson;
            height: 30px;
            position: absolute;
            width: 100%;
            top: 172px;
            display: block;
        }
        @media screen and (min-width: $medium-width) {
            top: 142px; 
        }

    }
    .menu {
         position: relative;
        @media screen and (min-width: $medium-width) {
            top: -10px;
        }
        li {
            margin-right: 0;
            color: $white;
            display: block;
            text-align: center;
            padding: 10px;
            @media screen and (min-width: $medium-width) {
            padding: 0 20px;
            
        } 
            &:not(:last-of-type) { 
                border-bottom: solid 1px $gray-base;
            }
            @media screen and (min-width: $small-width) {
                color: $white;
                display: inline-block;
                border-bottom: 0!important;
            }
            @media screen and (min-width: $medium-width) {
                &:first-of-type {
                    padding-left: 0;
                }
            }
        }
        &.header-navigation {
            
            ul {
                @extend %clearfix;
            }
            &--primary {
                @media screen and (min-width: $small-width) {
                    top:-15px;
                    display: inline-block;
                }
                @media screen and (min-width: $medium-width) {
                    display: inherit;
                    top: -5px; 
                } 
            }
            &--secondary {
                position: relative;
                text-transform: uppercase;
                text-align: center;
                li {
                    border-bottom: solid 1px $gray-base;
                    display: block;
                    padding: 15px;
                    margin-left: 0;
                    &.link-speed-entry{
                        display: none;
                        @media screen and (min-width: $medium-width) {
                            display: inline; 
                        }
                    }
                }
                @media screen and (min-width: $small-width) {
                    top: -150px;
                    text-transform: inherit;
                   
                    li {
                        border-bottom: 0;
                        display: inline-block;
                        padding: 0px;
                        margin-left: 20px;
                    }
                 @media screen and (min-width: $medium-width) {
                    text-align: right; 
                    top: -120px;
                    }   
                }
                li {
                    color: $white;
                }
            }
        }
    }
    .container {
        position: relative;
        &-social {
            text-align: center;
            margin-bottom: $spacing-base;
            @media screen and (min-width: $medium-width) {
                text-align: right;
            }
        }
    } 
    .header-logo {
        text-align: center;
         margin-bottom: 30px;
         @media screen and (min-width: $medium-width) {
            margin-bottom: 0px;
        }
        img, svg {
            width: 200px;
            max-height: 50px;
            margin-bottom: 15px;
            @media screen and (min-width: $small-width) {
                width: 335px;
            }
        }
        @media screen and (min-width: $medium-width) {
            text-align: left;
        }
    }
    .header-map{
        .text, img{
            display: inline-block;
            color: $color-secondary;
            font-size: 18px; 
        }
        .text{
            vertical-align: middle;
            font-family: $font-family--secondary;
        }
        .text--first{
            padding-right: 50px;
        }
        img{
            padding-right: 10px;
            padding-left: 20px;
            padding-top: 5px;
            height: 80px;
        }
    }
    .menu__trigger {
        position: absolute;
        right: $spacing-medium-vertical;
        top: $spacing-base;
        @media screen and (min-width: $small-width) {
            top: $spacing-base-vertical;
        }
        @media screen and (min-width: $small-width) {
            display: none;
        }
    }
    .nav {
        overflow: hidden;
        position: absolute;
        top: 103px;
        text-align: center;
        left: 0;
        right: 0; 
        background-color: white;
        z-index: 200;
        @media screen and (min-width: $small-width) {
            padding-left: 10px; 
            transform: skew(-50deg); 
            left: 350px;
            top: 158px;
            right: inherit; 
        } 
        @media screen and (min-width: $medium-width) {
            left: 48%;
            top: 128px; 
        } 
        ul {
            list-style: none;
            display: inline-flex;
            width: 100%;
        }
        li {
            display: inline-block;
            margin-right: 2px;
            background-color: $gray-light;
            @extend %transition-base;
            width: 33%;
            &:last-of-type{
                margin-right: 0;
            }
            @media screen and (min-width: $small-width) {
                margin-right: 10px;
                width: 120px;
                &:last-of-type{
                    margin-right: 10px;
                }
            }
             @media screen and (min-width: $medium-width) {
                    width: 200px;
                }
            &:hover {
                background-color: $gray-base;
                @extend %transition-base;
            }
            &.active {
                background-color: $crimson;
                color: $white;
                font-weight: bold;
                 @media screen and (min-width: $medium-width) {
                    width: 200px;
                }
                a {
                    color: $white;
                }
            }
          
        }
        li > a {
            display: block;
            color: $black;
            font-size: 15px;
            height: 44px;
            padding: 5px 40px;
            text-align: center;
             @media screen and (min-width: $small-width) {
                transform: skew(50deg);
                padding: 14px 20px;
                font-size: 13px;
            }
             @media screen and (min-width: $medium-width) {
                font-size: 20px;
                padding: 12px 10px;
             }
            &:hover {
                text-decoration: none;
                cursor: pointer;
                color: $white;
            }
            
        }

    }
    .nav__container {
        background-color: $shark;
        transform: translate3d(475px,0,0) skewX(30deg);
        visibility: hidden;
        opacity: 0;
        @extend %transition-base;
        width: 100%;
        top: 46px;
        @media screen and (min-width: $small-width) {
            transform: translate3d(0, 0, 0) skewX(0deg);
            text-align: left;
            top: 0;
            opacity: 1;
            visibility: visible;
            display: block;
            background-color: transparent;
            position:static;
            }
        &.is-open {
            transform: translate3d(0, 0, 0) skewX(0deg);
            visibility: visible;
            opacity: 1;
            
            @extend %transition-base;
        }
        &--secondary {
            background-color: $shark;
            display: none;
            height: 40px;
            @media screen and (min-width: $small-width) {
                display: block;
            }
            li {
                color: $white;
            }
        } 
    }
    &.nav-up {
        top: -150px;
    }
  
}