.container-step2 {
    background-color: white;
    padding: 0;
    @media screen and (min-width: $small-width) {
        padding: inherit;
    }
    .brands .container-brand, .brands .container-letter-separator {
        width: 49%;
        margin-left: 0;
        margin-right: 0;
        display: none;
         @media screen and (min-width: $small-width) {
            width: 150px;
            margin-left: 9px;
            margin-right: 9px;
             display: block;
        }
          @media screen and (min-width: $medium-width) {
            margin-left: 17px;
            margin-right: 17px;
        }
        height: 132px;
        h3 {
            padding: 15px;
        }
        .letter-separator {
            padding: 15px 0;
        }
    }
    .btn-results {
        position: relative;
        .loading-icon {
            position: absolute;
            top: 15%;
            left: 60%;
        }
    }
    .container-fluid{
        padding: 0;
    }
    .container-step2 {
        background-color: $white;
        padding: 0px;
        @media screen and (min-width: $small-width) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .dropdown-manufacturer-label{
        display: block;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        @media screen and (min-width: $medium-width) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .dropdown-manufacturer-chk{
        float: left;
        margin-top: 10px;
        @media screen and (min-width: $medium-width) {
            margin-top: 3px;
        }
    }

    .left-side {
        padding: 0;
        @media screen and (min-width: $small-width) {
            padding: 20px;
          }
        .categories {
            font-family: $font-family--secondary;
            font-size: 15px;
            background-color: $gray-xlight;
            ul li {
                padding: 16px 10px;
                @media screen and (min-width: $small-width) {
                    padding: 4px;
                }
            }
            > ul > li {
                border-bottom: 1px solid $gray-light;
                list-style: none;
            }
            .manufacturer {
                margin-left: 25px;
            }
            .subcategories{
                li{
                    padding: 2px 10px;
                }
            }
            .subcategories,
            .parttypes,
            .manufacturers {
                margin-left: 15px;
                li {
                    list-style: none;
                }
            }
            .subcategories > li > .submenu-handle > label {
                color: $black;
            }
            .manufacturer label {
                color: $black;
            }
            .submenu-handle {
                display: block;
                cursor: pointer;
                margin-left: 15px;
                padding-bottom: 10px;
                padding-top: 10px;
                width: 95%;
                input, i {
                    font-style: normal;
                   float: left; 
                }
                i{
                    margin-right: 5px;
                }
                label{
                    word-break: break-word;
                    display: block;
                    padding-left: 30px;
                }
            }
        }
        .chk-selected {
            font-weight: bold; 
        }
        .container-partial--latest-promo .container-promo img {
            width: 100%;
            padding: 10px;
        }
        .container-select-jobs{
            
            padding: 0px 13px 17px;
             @media screen and (min-width: $small-width) {
                background-color: $gray-xlight;
                border: 1px solid $gray-light;
                margin-top: 15px;
            }
            h3{
                padding: 10px 0;
            }
        }
        .container-category {
            h3 {
                padding: 15px;
            }
            
        }
        .container-select-keyword{
            padding: 0 13px 17px;
           
            .select2-selection__arrow b{
                display: none;
            }
        }
        .jobs-select{
            font-family: $font-family--secondary;
            font-size: 14px;
            padding: 8px 10px;
            height: 40px;
        }

        .parttype-select {
           padding: 8px 10px;
           height: 40px;
           font-family: $font-family--secondary;
           font-size: 14px;
       }
        .select2-container--default{
            &:after{
                border-color: #888 transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0;
                content: ' ';
                height: 0;
                right: 7px;
                position: absolute;
                top: 45%;
                width: 0;
            }
           
             .select2-results>.select2-results__options {
                max-height: 1000px;
            }
        }
        .select2-dropdown{
            nax-height: 400px;
        }
        .select2-selection--single{
            height: inherit;
            //margin: 0 12px;
        }
        
    }
    .dropdown-template {
        position: absolute;
        display: block;
        height: 300px;
        overflow-y: scroll;
        border: 1px solid #c6c6c6;
        background-color: #fff;
        z-index: 99;
        width: 85%;
        @media screen and (min-width: $small-width) {
            width: 500px;  
        }

        .dropdown-template-row {
            padding: 5px 10px;
            span {
                font-family: $font-family--secondary;
                font-size: 13px;
            }
        }

    }
    h3 {
        color: #565656;
        padding: 15px 0;
        margin-bottom: 0px;
    }
    h4 {
        padding: 10px;
        font-family: $font-family--secondary;
        display: inline-block;
    }
    label {
        font-family: $font-family--secondary; 
    }
    .right-side {
        padding: 0;
          @media screen and (min-width: $small-width) {
            padding: 20px;
          }
        .chk {
            width: 18px;
            height: 18px;
        }
        .container {
            padding: 0;
            @media screen and (min-width: $small-width) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        .container-buttons{
            background-color: $white;
            @extend %transition-base;
            margin-top: 15px;
               @media screen and (min-width: $small-width) {
                    margin-top: 0;
                    
               }
        }
        .container-search-part-number{
            background-color: $gray-xlight;
            border: 1px solid $gray-light;
            padding: 10px; 
            margin-top: 15px;
             @media screen and (min-width: $small-width) {
                 margin-top: 0px;
             }
             @media screen and (min-width: $medium-width) {
                 margin-top: 15px;
             }
              .go-button-validate{
                    color: $gray-base;
                    padding: 10px 20px;
                    font-weight: 700;
                    margin-top: 0;
                    text-transform: uppercase;
                    background-color: $gray-light;
                    @media screen and (min-width: $small-width) {
                         width: inherit;
                    }
                    &.valid{
                        background-color: $crimson; 
                        color: $white;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            h3{
                padding-bottom: 10px!important;
            }
            input[type=text]{
                background-color: white;
                padding: 11px 16px;
                font-family: $font-family--secondary;
                font-size: 14px;
                width: 85%;
                margin-bottom: 5px;
                @media screen and (min-width: $small-width) {
                    width: -moz-calc(100% - 65px);
                    width: -webkit-calc(100% - 65px);
                    width: -o-calc(100% - 65px);
                    width: calc(100% - 65px);
                }
            }
        }
        .container-toggle-applicative {
            font-size: 14px;
            padding: 5px;
            @extend %transition-base;
            @media screen and (min-width: $small-width) {
                background-color: $gray-xlight;
                border: 1px solid $gray-light;
                height: 100px;

            }
             @media screen and (min-width: $medium-width) {
                margin-top: 15px; 
            }
            .toggle-button {
                text-align: center;
                font-size: 14px;
                margin-top: 15px;
                 @media screen and (min-width: $small-width) {
                    margin-top: 25px;
                   
                 }
                @media screen and (min-width: $medium-width) {
                     font-size: 16px;
                }
                &--text{
                    
                    padding: 5px;
                    font-size: 16px;
                    @media screen and (min-width: $medium-width) {
                        top: 50%;
                        position: relative;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .container-brands{
            padding: 0;
            padding-bottom: 20px;
            .title {
                text-align: center;
                margin-top: 20px;
                @media screen and (min-width: $small-width) {
                    margin-top: 0;
                    text-align: left;
                }
            }
         
        }
        .container-button {
            display: none;
            @media screen and (min-width: $small-width) {
                display: block;
            }
            @media screen and (min-width: $medium-width) {
                padding-left: 20px;
                padding-right: 20px; 
            }
            button {
                width: 98%;
                margin: 0;
                height: 80px;
                background-color: $crimson;
                font-weight: bold;
                font-family: $font-family--secondary;
                text-transform: uppercase;
                text-align: left;
                color: #fff;
                font-size: 16.5px; 
                padding: 10px;
                cursor: pointer;
                background-size: 25%;
                background-repeat: no-repeat;
                background-position: right 10px center;
                @extend %transition-base;
                    @media screen and (min-width: $small-width) {
                        width: 98%;
                        height: 100px;
                        padding-top: 50px;
                        text-align: center;
                        background-position: center top 10px;
                        margin: 0;
                    }
                    @media screen and (min-width: $medium-width) {
                        padding: 30px;
                        padding-right: 30%;
                        text-align: left;
                        background-position: right 10px center;
                         margin: 15px 0 0;
                    }
                &.btn-results {
                    background-image: url('../images/kobrex-arrow-right-white.svg');
                }
                &.btn-reset {
                    background-image: url('../images/icon-reset.svg');
                    background-size: 20%;
                }
                i {
                    padding-right: 10px;
                }
            }
            button.disabled {
                cursor: default;
                background-color: $gray-light;
            }
        }
        .container-toggle-and-buttons{
            margin: 0;
        }
        .fixed-element{
            top: 0px;
            z-index: 98;
            padding-bottom: 10px;
            width: 89%;
            @extend %transition-base; 
            @media screen and (min-width: $small-width) {
                width: 90%;  
            }
            @media screen and (min-width: $medium-width) {
                top: 0;
                padding: 0;
                padding-bottom: 10px; 
                width: 71.1%;  
            }
            .container-toggle-and-buttons{
                margin: auto;
                padding: 0;
            }
             .container-toggle-applicative, .container-search-part-number{
                @media screen and (min-width: $medium-width) {
                    display: inline-block;
                    margin-top: 10px;
                }
                @extend %transition-base;
             }
             .container-button{
                    margin-top: 10px;
                    @media screen and (min-width: $small-width) {
                        margin-top: 0;
                    }
                    @media screen and (min-width: $medium-width) {
                         margin-top: 10px;
                    }
                 @extend %transition-base; 
            }
            .container-button button{
                margin-top: 0;
                 @extend %transition-base; 
            }
            .switch{
                display: block;
                margin-right: 10px;
                margin-left: auto;
                margin-right: auto;
               
            }
          
        }
        .manufacturers {
            .manufacturer {
                display: inline-block;
                border: 1px solid $gray-light;
                text-align: center;
                width: 49%;
                @media screen and (min-width: $small-width) { 
                    width: 150px;
                }
                .container-droplist-part-type {
                    background-color: $gray-light;
                    top: -10px;
                    height: 30px;
                    position: relative;
                    margin-top: 1px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                .dropdown-icon {
                    position: absolute;
                    bottom: 10px;
                    right: 5px;
                    cursor: pointer;
                }
                img {
                    width: 90%;
                    max-height: 90%;
                }
                input[type=checkbox] {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                }
                span {
                    display: block;
                    text-align: center;
                    font-family: $font-family--secondary;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    bottom: 8px;
                    padding-left: 5px;
                }
            }
        }
        .parttype-pop-header, .parttype-pop-footer{
            display: none;
        }
        .parttype-pop-footer {
            &:hover  {
                cursor: pointer; 
            }
        }
    }
    select {
        border: 1px solid #c6c6c6;
        -webkit-appearance: menulist;
        width: 100%;
        background-color: white;
        padding: 16px 10px;
         @media screen and (min-width: $small-width) {
             padding: 8px 10px;
         }        
    }
    .switch {
      position: relative;
      display: block;
      width: 60px;
      height: 34px;
      vertical-align: middle;
      margin-bottom: 10px;
      margin-right: 10px;
      margin-left: auto;
      margin-right: auto;
     
    }
    .switch input {
        display:none;
    }
    .slider {
        position: absolute;
         cursor: pointer;
         top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $shark;
        @extend %transition-base;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        @extend %transition-base;
    }

    input:checked + .slider {
        background-color: $crimson;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
} 

.parttype-pop-header{
     .override-color-gray:before{
        color : $gray-base;
    }
}
.title--step {
    text-align: center; 
    background-color: $gray-xlight;
    color: $crimson;
    font-size: 45px;
    text-transform: uppercase;
    padding-bottom: 5px;
    letter-spacing: 2px;
    @media screen and (min-width: $medium-width) {
            border-left: $crimson solid 15px;
    }
    .color--secondary {
        display: block;
        @media screen and (min-width: $medium-width) {
            display: inline-block;
        }
    }
    &__text{
        background-color: $gray-xlight;
        padding: 10px;
        @media screen and (min-width: $medium-width) {
            border-left: #EB2131 solid 15px;
        }
    }
}


@media screen and (min-width: $medium-width) {
    display: none;
}
.btn-mobile-reset, .btn-mobile-getresults{
    background-color: $crimson;
    width: 50px;
    height: 50px;
    color: $white;
    font-size: 20px;
    text-align: center;
    position: fixed;
    right: 140px;
    bottom: 10px;
    padding-top: 13px;
    z-index: 50;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0,0,0,.5);
    @media screen and (min-width: $medium-width) {
        display: none;
    }
    &.disabled {
        background-color: $gray-light;
    }
    &:hover {
        cursor: pointer;
    }
    
}
.btn-mobile-reset {
    right: 200px;
}


