.container-product {
    .addtocard-msg{
        margin-top: 10px;
        text-align: center;
        font-size: 10px;
        @media screen and (min-width: $small-width){
            margin-top: 140px;
            text-align: right;
        }
    }
	.btn-back{
		text-transform: uppercase;
		background-image: url(../images/k-kobrex-partial-white.svg);
		background-repeat: no-repeat;
		background-color: $crimson;
		background-size: 25%;
		background-position: left 10px center;
		height: 70px;
		text-align: right;
		width: 195px;
		font-size: 12px;
		padding-left: 40px;
		margin-bottom: 20px;
        padding-top: 20px;
	}
	.container{
		padding: 0;
		@media screen and (min-width: $small-width){
			padding: inherit;
		}
	}
    .container-asset{
        p{
            word-break: break-all;
            display: table; 
            font-size: 11px;
            &:hover{
                color: $crimson;
            }
        }
    }
	.container-btn-back{
		text-align: center;
		@media screen and (min-width: $small-width){
			text-align: right;
		}
	}
	.container-price-quantity{
		font-family: $font-family--secondary;
		color: $black;
		.addtocart-button{
            min-width: 165px;
            @media screen and (min-width: $small-width){
    			float: right;
    			text-transform: uppercase;
    			margin-top: 90px;
            }
		}
		.container-price{
			&:hover{
				.cost-price{
					display: block;
				}
			}
		}
		.availability{
            margin-top: 10px;
			font-size: 14px;
            text-align: center;
            @media screen and (min-width: $small-width){
                text-align: right;
            }
		}
		.quantity-control{
		    border: 1px solid #DCDCDC;
		    background-color: #FFF;
		    text-transform: uppercase;
		   	padding: 10px 5px;
		    color: #313236;
		    width: 105px;
		    height: 40px;
		    margin: 20px auto;
		    font-size: 14px;
		    @media screen and (min-width: $small-width){
			    margin: 20px 0;
			    right: 0;
                position: absolute;
			}
		    input[type=number] {
			    color: #EB2131;
			    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
			    font-weight: 700;
			    font-size: 20px;
			    width: 60px;
			    padding: 5px 10px;
			    float: right;
			    margin-top: -10px;
			    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
				   opacity: 1;
				}
			}
		}
        .divider-fee{
            border: 1px solid $gray-light;
            margin: 0.5em 0;

        }
		
		.save, .tax, .deposit, .regular-price, .cost-price{
			font-size: 14px;
			text-align: center;
			@media screen and (min-width: $small-width){
				text-align: right;
			}
		}
		.cost-price{
			display: none;
		}
        .tax, .deposit{
            color: green;
        }
        .unit-price{
            font-weight: bold;
            font-size: 40px;
            text-align: center;
            @media screen and (min-width: $small-width){
                text-align: right;
            }
        }

	}
	.container-tab-content{
		background-color: $gray-xlight;
		padding: 30px;
		margin-bottom: 60px;
		font-family: $font-family--secondary;
		color: $shark;
        h3{
            color: $crimson;
            text-align: center; 
            margin-bottom: 0;
            @media screen and (min-width: $small-width){
                text-align: left;
            }
            &:not(:first-of-type){
            margin-top: 40px;
            }
        }
        .container-specification{
            table{
                 tr:nth-child(even){
                    background-color: $gray-light;
                 } 
                td{
                    padding: 10px;
                     &:first-of-type{
                        width: 50%;
                    }
                }
            }
        }
        .container-description{
            table td{
                text-align: left;
            }
        }
        .container-interchange{
            h3{
                border-bottom: 1px solid $gray-light;
                margin-bottom: 20px;
            }
             span{
             font-size: 12px;
                 &:hover{
                    cursor: pointer;
                    background-color: $gray-base;
                     color: $white;
                }
            }
            .tooltip {
                position: relative;
                display: inline-block;
            }

            .tooltip .tooltiptext {
                visibility: hidden;
                min-width: 200px;
                background-color: $shark;
                color: #fff;
                text-align: left;
                border-radius: 2px;
                padding: 5px;
                position: absolute;
                z-index: 1;
            }

            .tooltip:hover .tooltiptext {
                visibility: visible;
            }
        }
	}
	.container-button-toggle{
		text-align: center;
		.button-toggle{
			display: inline-block;
			&:hover{
				cursor: pointer;
			}
		}
	}
	.container-content{
		display: none;
		&.active{
			display: block;
		}
	}
	.container-image{
		@media screen and (min-width: $medium-width){
			border-right: 1px solid $gray-light;
		}
	}
	.container-logo{
		text-align: center;
		@media screen and (min-width: $small-width){
			text-align: left;
		}
        img{
            max-width: 100%;
        }
       
	}
	.container-related-product{
		h2{
			text-align: center;
			@media screen and (min-width: $small-width){
				text-align: left;
			}
		}
	}
	.header{
		background-color: $gray-xlight;
		padding: 20px 0 5px;
		.applicative-info{
			display: none;
            font-size: 14px;
			font-family: $font-family--secondary;
			.fits-on{
				margin-top: 20px;
				text-transform: uppercase;
				font-weight: bold;
				color: $shark;
				margin-bottom: 5px;
				&__list{
					color: $shark;
					list-style-type: none;
					margin-bottom: 20px;
					.header-fit{ 
						color: #EB2131;
                        font-weight: 700;
					}
                    .sub-fits{
                        margin-left: 25px;
                        color: #878787;
                        font-weight: 400;
                        font-size: 14px;
				    }
                
                }
			}
		}
		.divider{
			border-top: 1px solid $gray-light;
		}
	}
	.made-for{
		color: $gray-base;
		font-weight: bold;
		font-size: 18px;
		text-align: center;
		@media screen and (min-width: $small-width){
			text-align: left;
		}
	}
	.nav{
		margin-top: 50px;
		font-family: $font-family--secondary;
		@media screen and (min-width: $small-width){
			-webkit-transform: skew(-50deg);
	   		 transform: skew(-50deg);
	   		 margin-left: 25px;
	   	}
   		 
		ul{
			list-style: none;
		    display: -webkit-inline-box;
		    display: -ms-inline-flexbox;
		    display: inline-flex;
		    li{
		    	background-color: #ccc;
		      	color: $shark;
		      	margin-right: 2px;
		      	@extend %transition-base;
                width: 33%;
		      	@media screen and (min-width: $small-width){
		      		width: 150px;
		      		margin-right: 10px;
		      	}
		      	@media screen and (min-width: $medium-width){
		      		width: 200px;
		      	}
			    &.active {
				    background-color: $crimson;
				    color: $white;
				    font-weight: 700;
				}
				a{
    				font-size: 13px;
   					padding: 12px 10px;
   					text-align: center;
   					display: block;
   					@media screen and (min-width: $small-width){
   						font-size: 14px;
   						-webkit-transform: skew(50deg);
    					transform: skew(50deg);
    				}
   					&:hover{
   						text-decoration: none;
   						color: #FFF;
   					}
				}
				&:hover{
					cursor: pointer;
					background-color: $gray-base;
					@extend %transition-base;
				}
		    }
		    
		}
        .container-product-info-tab {
            width: 100%;
        }
        
	}
    .part-custom-attribute, .part-attribute{
        td{
            border-bottom: solid #DCDCDC;
             border-bottom-width: 1px;
             width: 50%;
            @media screen and (min-width: $small-width){
                bottom-border-width: 0;
                width: inherit;
            }
        }
    }
	.parttype-brand{
		color: $shark;
		font-weight: bold; 
		text-align: center;
		@media screen and (min-width: $small-width){
			text-align: left;
		}

	}
	.part-info{
		font-family: $font-family--secondary;
		text-align: center;
		@media screen and (min-width: $small-width){
			text-align: left;
		}

		.brand{
			font-weight: bold;
			color: $shark;
			font-size: 14px;

		}
        h3{
            margin-top: 20px;
            margin-bottom: 5px;
            color: $gray-dark;
        }
		.label{
			font-weight: bold;
			color: $shark;
			font-size: 18px;
		}
		.part-number{
			color: $crimson;
		}
	}
	.related-product{
		background-color: $gray-xlight;
		text-align: center;
		padding: 20px;
		font-family: $font-family--secondary;  
		margin-bottom: 20px;
		@media screen and (min-width: $small-width){
			&:not(:first-of-type){
				border-left: 10px solid white;
			}
		}
		.btn-detail{
			background: #FFF;
   			border: 1px solid #EB2131;
    		color: #313236;
    		text-transform: uppercase;
		}
		.brand{
			font-weight: bold;
			color: $crimson;
			font-size: 12px;
			padding: 5px;
		}
		.label{
			font-weight: bold;
			color: $shark;
			font-size: 18px;
		}
		.part-type{
			text-transform: uppercase;
			font-weight: bold;
			color: $shark;
			font-size: 14px;
		}
		.price{
			font-weight: bold;
			color: $crimson;
			font-size: 18px;
		}
	}
    .slick-slide {
        outline: none;
    }
    table{
        border-collapse: inherit;
        width: 100%;
        font-size: 14px;
        td{
            vertical-align: top;
            padding: 5px;
            text-align: left;
            &:nth-of-type(1){
                color: $gray-base;
                text-align: right;
                 @media screen and (min-width: $small-width){ 
                    text-align: left;
                }
            }
        }
    }
	.vehicle{
		color: $crimson;
		font-size: 50px; 
		line-height: 55px;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
		 @media screen and (min-width: $small-width){ 
		 	text-align: left;
		 }

	}
   
    .slick-arrow{
    position: absolute;
    bottom: 5px;
    color: transparent;
    background-image: url(/dist/images/icon-arrow.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 40px;
    height: 45px;
    display: none!important;
         @media screen and (min-width: $medium-width){
            display: inline-block!important;
         }
        &:hover{ 
            cursor: pointer; 
        }
    }
    .slick-list{
        padding: 0!important;
    }
    .slick-next{
        right: 0;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    .slick-slide{
        max-width: 100%;
        @media screen and (min-width: $medium-width){
            width: 550px;
        }
        img{
            margin: auto;
            max-width: 100%;
            max-height: 550px;
        }
    }
    .slick-slider {
        margin-bottom: 0;
        position:relative;
    }
    ul.slickslide {
        padding-left:0;
        max-width:100%;
    }
    .slick-slider .slick-track {
        min-width:100%;
    }
    .slick-dots button img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;

        &:hover{
            cursor: pointer;
        }
    }
    .slick-dots {
        line-height:0;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        vertical-align: bottom;
        @media screen and (min-width: $medium-width){
            margin-bottom: 0;
        }

    }

    .slick-dots li, .slick-dots li button:before {
        margin:0;
        padding:0;
    }
    .slick-dots li {
        margin: 5px; 
        border:1px solid $crimson; 
        display:inline-block; 
        width:auto;
        height: 48px;
        overflow: hidden;
        &.slick-active{
            border: 3px solid $crimson;
        }
    }

    .slick-slider .slick-track {
        min-width:100%;
    }
    .slick-dots li button {
        margin:0;
        height:auto;
        width:100%;
        padding:0;

    }

    .slick-dots li button.tab { 
        clear:both;   
        display:block;  
        width: 50px;
        height: 50px;
    }
    .slick-dots li button img { width:100%; max-height:100%; height:auto;   }
    .slick-dots li button:before {
        content:''; 
        display:block; 
        width:100%;  
        max-width:150px; 
        overflow:hidden; 
        float:none;  
        position:absolute;
        width:100%;
        }
    .slick-thumbs {
        position:absolute;
        left: -9999px;
    }
}
.page-template-tpl-part-php{
    overflow: hidden;
}