.slider {

	// Slider elements (dots, arrow, slide, etc..)
	.slick-slide {
		outline: 0; 

	}
	.slick-dots {
		width: 100%;
		position: absolute;
		bottom: 0;
		text-align: center;
		padding-bottom: 0;

		li {
			display: inline-block;
			margin-right: 15px;

			&.slick-active {
                cursor: default;
                
                button {
                    cursor: default;
                }
                
				button:before {
					background-color: $white;
				}
			}

			button { 
				position: relative;
				cursor: pointer;
				color: transparent;
				border: 0;
				outline: none;
				background: transparent;

				&:before {
					position: absolute;
					content: "";
					top: 0; left: 0;
					width: 16px;
					height: 16px;
					border-radius: 8px;
					background-color: darken($gray-light, 20%);
					border: 2px solid $color-primary;
                    @extend %transition-base;
				}
			}
            
            @media screen and (min-width: $nohover-width) {
                &:hover {
                    button {
                        &:before {
                            background-color: $white;
                        }
                    }
                }
            }
		}
	} // @end slick dots

	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		//font-size: em(28px);
		//color: $white;
		cursor: pointer;
		z-index: 50;
		outline: 0;

		&.slick-next {
			right: 0;
			@media screen and (min-width: $large-width) {
				right: 20px;	
			}	 
		}
		&.slick-prev {
			left: 0;
			@media screen and (min-width: $large-width) {
				left: 20px;	
			}	
		}
	} // @end slick arrow


	&--promo {
		margin-top: 30px;
		margin-bottom: 30px;
		padding-bottom: 20px;

		&__item {
			min-height: 300px;
		}

		&__img {
			margin-bottom: 20px;
			img {
				@extend .img-responsive;
				margin-left: auto;
				margin-right: auto;
			}
		}
		&__details {
			color: #4C4C4C;
    		line-height: 1.3;
    		letter-spacing: 1px;
    		font-size: 14px;
		}

		.slick-dots {
			bottom: -50px;
			li button:before {
				background-color: $white;
			}

			li.slick-active button:before {
				background-color: $color-primary;
			}

		}

	}

}